import { Container } from "react-bootstrap";
import imgEncrypt from './encrypt.png';
import imgPassword from './password.png';
import imgExpiry from './expiry.png';
import imgBgsecurity from './bg-security.png';
import imgReadonly from './read-only.png';
import imgRevoke from './revoke.png';
import imgChangelog from './changelog.png';
import './Security.css';

function Security() {
    return (
        <Container data-aos="fade-up" className="main-content">
            <div className="section-title text-center">
                <h2>Security</h2>
                <h3>We take security and privacy very seriously.</h3>
                <img src={imgBgsecurity} alt="Hero Imgs" data-aos="zoom-out" data-aos-delay="100"></img>
            </div>

            {/* <div className="row justify-content-center">
                <div className="col-lg-2 col-md-2">
                    <img src={imgEncrypt}alt="img" className="security-img" />
                </div>

                <div className="col-lg-10 col-md-8">
                    <p style={{textAlign: 'left', fontSize: '20px'}}>
                        <b>Your communication is encrypted. </b>
                        The traffic between your computer and this website is encrypted with a Digital Security Certificate.
                    </p>
                </div>
            </div>

            <div className="row justify-content-center">
                <div className="col-lg-2 col-md-2">
                    <img src={imgPassword}alt="img" className="security-img" />
                </div>

                <div className="col-lg-10 col-md-8">
                    <p style={{textAlign: 'left', fontSize: '20px'}}>
                        <b>Your API keys are NOT stored on the server. </b>
                        They are only used once in order to fetch your Meraki config from the Dashboard API.
                    </p>
                </div>
            </div>

            <div className="row justify-content-center">
                <div className="col-lg-2 col-md-2">
                    <img src={imgExpiry}alt="img" className="security-img" />
                </div>

                <div className="col-lg-10 col-md-8">
                    <p style={{textAlign: 'left', fontSize: '20px'}}>
                        <b>Your backup files are NOT stored on the server. </b>
                        Upon successful backup, you will receive a secure link to download your files. Your link will expire
                        after 60 minutes and the files will be removed from the server.
                    </p>
                </div>
            </div> */}

            <div className="section-title text-center">
                <h2>Security recommendations.</h2>
            </div>

            <div className="row-security justify-content-center">
                    <p style={{textAlign: 'center', fontSize: '20px'}}>
                        <b>Use a read-only account.</b>
                    </p>
                    <p style={{textAlign: 'justify', fontSize: '18px'}}>
                          The best practice is to create a dedicated user account with read-only permissions to your
                          Meraki Dashboard. In this case, it will not be possible to make any changes using the API
                          key generated under this account.
                    </p>
                    <img src={imgReadonly}alt="img" />
            </div>

            <div className="row-security justify-content-center">
                    <p style={{textAlign: 'center', fontSize: '20px'}}>
                        <b>Rotate your keys regularly.</b>
                    </p>
                    <p style={{textAlign: 'justify', fontSize: '18px'}}>
                        It is a great practice to re-generate your API keys from time to time. Just like changing your
                        password regularly greatly reduces the risk of potential loss or exposure of your API key.
                    </p>
                    <img src={imgRevoke}alt="img" />
            </div>

            <div className="row-security justify-content-center">
                    <p style={{textAlign: 'center', fontSize: '20px'}}>
                        <b>Check your Change log regularly.</b>
                    </p>
                    <p style={{textAlign: 'justify', fontSize: '18px'}}>
                        If you suspect that the changes might have been made via API, check the change log available
                        under <b>Organization > Change Log</b> to make sure only authorized changes have been made.
                        BackupMeraki does not make any changes to your configuration so you should not see any changes made
                        under your provided account.
                    </p>
                    <img src={imgChangelog}alt="img" />
            </div>

        </Container>
    );
}

export default Security;