import { Container } from "react-bootstrap";
import React from "react";
import "./styles.scss";

function Footer() {

  return (
    <footer className="padd-section">
      <Container className="footer" data-aos="fade-in">
        <div className="row">
          <div className="col-md-12 col-lg-4">
            <div className="footer-logo">
              <a className="navbar-brand" href="/">
                BackupMeraki
              </a>
              <p>
                This online tool allows you to back up your Meraki Dashboard
                configuration.
              </p>
            </div>
          </div>

          <div className="col-sm-6 col-md-3 col-lg-2">
            <div className="list-menu">
              <h4>Terms of use</h4>
              <ul className="list-unstyled">
                <li>
                  <a href="/privacy-policy">
                    Privacy Policy
                  </a>
                </li>

                <li>
                  <a href="/terms-of-service">
                    Terms of Service
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-sm-6 col-md-3 col-lg-5">
            <div className="list-menu">
              <h4>Contact us</h4>
              <ul className="list-unstyled">
                <li>
                  <a
                    href="https://www.nexivity.net"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Nexivity Pty Ltd
                  </a>
                </li>
                <li>
                  <a
                    href="https://apps.meraki.io/en-US/apps/426592/backup-meraki"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Cisco Meraki Ecosystem Partner
                  </a>
                </li>
                <li>
                  <a
                    href="mailto:contact@backupmeraki.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    E-mail: <u>contact@backupmeraki.com</u>
                  </a>
                </li>
                <li>
                  <p>
                    Address: Level 2, 11-31 York St, Sydney, NSW 2000, Australia
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="copyrights">
          <div className="container">
            <p>
              &copy; Copyrights Backup Meraki 2021-2024. All rights reserved.
              Powered by{" "}
              <a
                href="https://www.nexivity.net"
                target="_blank"
                rel="noreferrer"
              >
                <u>Nexivity</u>
              </a>
              .
            </p>
          </div>
        </div>
      </Container>
    </footer>
  );
}

export default Footer;
