import React from "react";
import { Container, Nav, Navbar, Button, NavDropdown } from "react-bootstrap";
import Alert from 'react-bootstrap/Alert';
import { NavLink } from "react-router-dom";
import "./styles.scss";

export default function Header() {

  return (
    <header className="header">
      <Alert variant="success">
        <Alert.Heading className="center-text">New Feature: Data Storage Region!</Alert.Heading>
        <p className="center-text">
        Go to <b>Settings > General > Data Storage Region</b> to select your preferred data storage location. <Alert.Link href="https://app.backupmeraki.com/" target="_blank">Check it out now</Alert.Link>.
        </p>
      </Alert>
      <Navbar expand="lg">
        <Container>
          <Navbar.Brand>
            <div className="logo">
              <h1>
                <a href="/">
                  Backup<span>Meraki</span>
                </a>
              </h1>
            </div>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse>
            <Nav className="nav-menu">
              <NavLink to="/" className="nav-link">
                Home
              </NavLink>
              <NavLink to="/how-it-works" className="nav-link">
                How it works
              </NavLink>
              <NavLink to="/security" className="nav-link">
                Security
              </NavLink>
              <NavLink to="/pricing" className="nav-link">
                Pricing
              </NavLink>
              <NavLink to="/contact" className="nav-link">
                FAQ
              </NavLink>
              <NavLink to="/about-us" className="nav-link">
                About Us
              </NavLink>
              <div>
                <Button href="https://app.backupmeraki.com/register" target="_blank" variant="outline-success" className="button-unfilled">
                  Sign up
                </Button>{" "}
                <Button href="https://app.backupmeraki.com/login" target="_blank" variant="success" className="button-filled">
                  Log in
                </Button>
              </div>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
}
