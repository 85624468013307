import { Auth, Hub } from "aws-amplify";
import Cookies from "js-cookie";
import React, { createContext, useContext, useEffect, useState } from "react";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [signInUser, setSignInUser] = useState();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const updateUser = async () => {
      try {
        setIsLoading(true);
        const user = await Auth.currentAuthenticatedUser()
        await setSignInUser(user)
        const token = user?.signInUserSession?.accessToken?.jwtToken;
        Cookies.set('token', token)
        setIsLoading(false);
      } catch {
        setSignInUser(null)
        setIsLoading(false);
      }
    }
    Hub.listen('auth', updateUser)
    updateUser()
    return () => Hub.remove('auth', updateUser) // cleanup
  }, []);

  return (
    <AuthContext.Provider
      value={{
        signInUser,
        setSignInUser,
        isLoading,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
