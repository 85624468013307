import { Container } from "react-bootstrap";
import imgBenefits from "./benefits.png";
import imgPartner from "./meraki_partner.png";
import imgMarketplace from "./marketplace.png";
import "./Aboutus.css";

function Aboutus() {
  return (
    <Container className="main-content" data-aos="fade-up">
      <div className="section-title text-center">
        <h2>About us</h2>
      </div>

      <div className="row">
        <div
          className="col-md-4 col-lg-4"
          data-aos="zoom-in"
          data-aos-delay="100"
        >
          <div className="feature-block">
            <img src={imgMarketplace} alt="img" className="img" />
          </div>
        </div>

        <div
          className="col-md-8 col-lg-8"
          data-aos="zoom-in"
          data-aos-delay="100"
        >
          <div className="feature-block">
            <h3 className="aboutus-h3">Verified Cisco Meraki Marketplace application.</h3>
            <p className="aboutus-p">
              We are proud to announce that BackupMeraki is an officially verified application 
              available on the {" "}
              <a
                href="https://apps.meraki.io/en-US/apps/426592/backup-meraki"
                target="_blank"
                rel="noreferrer"
              >
                Cisco Meraki Marketplace
              </a>.
            </p>
            <p className="aboutus-p">
              Our application has undergone rigorous 
              testing and verification to ensure it meets the highest standards for security and performance. 
              By choosing BackupMeraki, you're not just choosing a reliable backup solution; 
              you're also ensuring that your data management is in safe hands.
            </p>
          </div>
        </div>
      </div>

      <div className="row">
        <div
          className="col-md-4 col-lg-4"
          data-aos="zoom-in"
          data-aos-delay="100"
        >
          <div className="feature-block">
            <img src={imgPartner} alt="img" className="img" />
          </div>
        </div>

        <div
          className="col-md-8 col-lg-8"
          data-aos="zoom-in"
          data-aos-delay="100"
        >
          <div className="feature-block">
            <br></br><br></br>
            <h3 className="aboutus-h3">Welcome to Nexivity - Cisco Meraki Ecosystem Partner!</h3>
            <p className="aboutus-p">
              BackupMeraki is an online service brought to you by{" "}
              <a
                href="https://www.nexivity.net"
                target="_blank"
                rel="noreferrer"
              >
                Nexivity
              </a>.
                Nexivity is a registered{" "}
              <a
                href="https://locatr.cloudapps.cisco.com/WWChannels/LOCATR/pf/index.jsp#/partner/3857813"
                target="_blank"
                rel="noreferrer"
              >
                Cisco Meraki Technology Partner
              </a>.
            </p>
            <p className="aboutus-p">
              Our core expertise is Network Automation and our mission is to run
              Networks on autopilot. We are harnessing the power of software and
              automation to make networks more agile, reliable, and secure.
            </p>
            <p className="aboutus-p">
              We live and breathe Network Automation. BackupMeraki is a great
              example of how we can solve a complex challenge using the power of automation and APIs.
            </p>
            <p className="aboutus-p">
            With our deep expertise in networking and intimate knowledge of
              Cisco Meraki hardware and software  we are well-equipped to elevate your organization's network management to the next level.
            </p>
          </div>
        </div>
      </div>

      <div className="row">
        <div
          className="col-md-4 col-lg-4"
          data-aos="zoom-in"
          data-aos-delay="100"
        >
          <div className="feature-block">
            <img src={imgBenefits} alt="img" className="img" />
          </div>
        </div>

        <div
          className="col-md-8 col-lg-8"
          data-aos="zoom-in"
          data-aos-delay="100"
        >
          <div className="feature-block">
            <h3 className="aboutus-h3">Benefits of Network Automation.</h3>
            <p className="aboutus-p">
              Network Automation allows customers to enjoy a number of benefits
              such as:
              <li>To speed up network provisioning and deployment,</li>
              <li>
                To improve network operations efficiency and to greatly reduce
                MTTR (minimum time to resolution),
              </li>
              <li>
                To bring an extra layer of safety and security to the network,
              </li>
              <li>
                To extend the capabilities of the network beyond the standard
                feature set offered by networking vendors
              </li>
              <li>
                To reduce the TCO (total cost of ownership) of the network
                infrastructure,
              </li>
              <li>To greatly reduce compliance and regulatory risks. </li>
            </p>
          </div>
        </div>
      </div>
    </Container>
  );
}
export default Aboutus;
