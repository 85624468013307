import { Container } from "react-bootstrap";

function Restore() {
    return (
        <Container data-aos="fade-up">
            <div className="section-title text-center">
                <h2>Restore</h2>
                <h3>Placeholder for restore section.</h3>
            </div>
            <div className="row justify-content-center">
                <div className="col-lg-10 col-md-8">
                    <p style={{textAlign: 'left', fontSize: '20px'}}><i className="fa fa-check-circle" style={{color: '#71c55d', fontSize: '20px'}}></i><b>Information about restore</b>. More information about restore</p>
                </div>
            </div>
        </Container>
    );
}

export default Restore;