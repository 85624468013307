import { Container, Accordion } from "react-bootstrap";
import imgBgcontact from "./bg-contact.png";
import "./Contact.css";

function Contact() {
  return (
    <Container data-aos="fade-up" className="main-content">
      <div className="section-title text-center">
        <h2>FAQ and Feedback</h2>
        <div className="contact-row">
          <img
            src={imgBgcontact}
            alt="Hero Imgs"
            data-aos="zoom-out"
            data-aos-delay="100"
          />
        </div>
      </div>

      <div className="row justify-content-center">
        <div id="faq" className="col-lg-10 col-md-8">
          <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey="0">
              <Accordion.Header className="black-text">
                What new features are included in BackupMeraki v2.0?
              </Accordion.Header>
              <Accordion.Body style={{ textAlign: "left" }}>
                <p>We bundled a lot of new features in our latest release such as:</p>
                <li><b>Automatic Backup.</b> You can now schedule backups to occur automatically on a daily, weekly, or monthly basis.</li>
                <li><b>Backup History.</b> Keep track of all your backup activities with a comprehensive backup history, allowing you to easily review previous backup details and statuses.</li>
                <li><b>Enhanced Security.</b> We've enhanced the security of your data with safe and secure storage for both your API key and backup data, giving you peace of mind that your information is protected.</li>
                <li><b>Organization List.</b> Manage multiple organizations effortlessly with the new organization sync feature.</li>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header className="black-text">
                How does this online tools work?
              </Accordion.Header>
              <Accordion.Body style={{ textAlign: "left" }}>
                <p>This tool collects your Meraki Dashboard configuration using the
                API key and writes the config data into files. Upon completion,
                you will receive a signed secure URL to download your backup
                files. Please, take note that the URL will expire after 60
                minutes and your files will be removed from the server.</p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>
                Why does the backup takes several minutes to complete?
              </Accordion.Header>
              <Accordion.Body style={{ textAlign: "left" }}>
                <p>This tool makes a lot of API GET (read-only) calls in order to
                collect the configuration. Meraki Dashboard API has a limit of
                10 API calls per second per organization therefore it might take
                several minutes to get your config via API.</p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header>
                What information is this tool able to backup?
              </Accordion.Header>
              <Accordion.Body style={{ textAlign: "left" }}>
                <p>This tool is able to back up about 95% of your configuration
                (both Networks-wide and Organisation-wide). Basically,
                everything that you can get via API.</p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
              <Accordion.Header>
                I accidentally removed one of my networks. How can I restore the
                config?
              </Accordion.Header>
              <Accordion.Body style={{ textAlign: "left" }}>
                <p>As of today, the restore process has to be done manually. The
                information collected during backup helps you to ensure that
                your settings match exactly the previous config.</p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="5">
              <Accordion.Header>
                Is there any risk of this tool changing my Meraki config?
              </Accordion.Header>
              <Accordion.Body style={{ textAlign: "left" }}>
                <p>No, absolutely not. The tool makes GET calls which are
                essentially read-only. If you are still unsure, you could use
                the API token for a read-only user.</p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="6">
              <Accordion.Header>
                Still got questions? Want to share any feedback?
              </Accordion.Header>
              <Accordion.Body style={{ textAlign: "left" }}>
                <p>Feel free to drop us an email at{" "}
                <a href="mailto:contact@backupmeraki.com" target="_blank" rel="noreferrer">
                  <b>contact@backupmeraki.com</b>
                </a></p>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>
    </Container>
  );
}
export default Contact;
