import { Container } from "react-bootstrap";
import './PrivacyPolicy.css';

function PrivacyPolicy() {
    return (
        <Container data-aos="fade-up" className="main-content">
            <div className="section-title">
                <h2>Privacy Policy</h2>
            </div>
            <div className="row-privacy-policy justify-content-center">
                <p>
                    <b>Effective Date: April 25, 2024</b>
                </p>
                <p>
                    Your privacy is important to us. We are committed to protecting your privacy and 
                    ensuring you have a positive experience on our websites and in using our products 
                    and services ("Service" or "Services").
                </p>
                <p>
                    This Privacy Policy describes our policies and procedures on the collection, 
                    use, and disclosure of your information through BackupMeraki when you access the {}
                    <a href="https://backupmeraki.com">backupmeraki.com</a> and {}
                    <a href="https://app.backupmeraki.com">app.backupmeraki.com</a>.
                    It also informs you about your privacy rights and how the law protects you.
                </p>
                <p>
                    By using the Service, you agree to the collection and use of information in accordance 
                    with this Privacy Policy. We use Your Personal data to provide and improve our Service.
                </p>
            </div>
            <div className="row-privacy-policy justify-content-center">
                <h4>Definitions</h4>
                <p>
                For the purposes of this Privacy Policy:
                <li>Account means a unique account created for you to access our Service or parts of our Service.</li>
                <li>Company (referred to as either "the Company", "We", "Us", or "Our" in this Agreement) refers to Nexivity Pty Ltd, 
                    Level 2, 11-31 York street, Sydney, NSW 2000.
                </li>
                <li>Cookies are small files that are placed on your computer, mobile device, or any other device by a website, 
                    containing details of your browsing history on that website among its many uses.
                </li>
                <li>
                    Country refers to: New South Wales, Australia
                </li>
                <li>
                    Device means any device that can access the Service such as a computer, a mobile phone or a tablet.
                </li>
                <li>
                    Personal Data is any information that relates to an identified or identifiable individual.
                </li>
                <li>
                Usage Data refers to data collected automatically, either generated by the use of the Service or from the 
                Service infrastructure itself (for example, the duration of a page visit).
                </li>
                </p>
            </div>
            <div className="row-privacy-policy justify-content-center">
                <h4>1. Information Collection</h4>
                <h3 style={{ textAlign: 'left' }}>Personal Data</h3>
                <p>
                While using our Service, we may ask you to provide us with certain personally 
                identifiable information that can be used to contact or identify you. 
                <li>Email address</li>
                <li>First name and last name</li>
                <li>Organization name</li>
                <li>Mobile phone number</li>
                <li>Cisco Meraki API key</li>
                <li>Payment information (when using paid plan only)</li>
                This information is necessary to create your account and to provide you with our services, 
                including backing up your Cisco Meraki Dashboard configuration.
                </p>
                <h3 style={{ textAlign: 'left' }}>Service Data</h3>
                <p>
                Using the Cisco Meraki API key, we access and store your Cisco Meraki 
                Dashboard configuration data. This includes but is not limited to:
                <li>Organizations you have access to,</li>
                <li>Configuration settings of devices and networks.</li>
                This data is essential for performing the backup services you request and 
                is not used for personal identification. Users have access to deleting their backup data.
                </p>
                <h3 style={{ textAlign: 'left' }}>Usage Data</h3>
                <p>
                Usage Data is collected automatically when using the Service.
                </p>
                <p>
                Usage Data may include information such as your device's IP address, 
                browser type, browser version, the pages of our Service that you visit, the time and date of your visit, 
                the time spent on those pages, unique device identifiers and other diagnostic data.
                </p>
                <p>
                When you access the Service by or through a device, we may collect certain information automatically, 
                including, but not limited to, the type of a device you use, your device unique ID, the IP address of your 
                device, your operating system, the type of your Internet browser you use, unique device identifiers and 
                other diagnostic data.
                </p>
                <p>
                We may also collect information that your browser sends whenever you visit our Service or when you access the Service.
                </p>
                <h3 style={{ textAlign: 'left' }}>Google User Data</h3>
                <p>
                In addition to the personal data listed previously, if you choose to authenticate using Google,
                we collect the following information provided by Google:
                </p>
                <li>First name</li>
                <li>Last name</li>
                <li>Email address</li>
                <p>This information is used to facilitate the registration process, and simplify the login procedure.</p>
                <h3 style={{ textAlign: 'left' }}>Tracking Technologies and Cookies</h3>
                <p>
                We use Cookies and similar tracking technologies to track the activity on our Service and store certain information. 
                Tracking technologies used are beacons, tags, and scripts to collect and track information and to improve and analyze 
                our Service.
                </p>
            </div>
            <div className="row-privacy-policy justify-content-center">
                <h4>2. Use of information</h4>
                <p>
                The Company may use personal data for the following purposes:
                <li>To provide and maintain our Service, including to monitor the usage of our Service.</li>
                <li>To manage your account. The personal data you provide can give you access to different 
                    functionalities of the Service that are available to you as a registered user.
                </li>
                <li>For the performance of a contract: the development, compliance and undertaking of 
                    the purchase contract for the products, items or services you have purchased or of any other contract with 
                    us through the Service.
                </li>
                <li>To contact you by email, telephone calls, SMS, or other equivalent forms of electronic communication, 
                    such as a mobile application's push notifications regarding updates or informative communications related 
                    to the functionalities, products or contracted services, including the security updates, when necessary or 
                    reasonable for their implementation.
                </li>
                <li>To provide you with news, special offers and general information about other goods, services and events 
                    which we offer that are similar to those that you have already purchased or enquired about unless you have 
                    opted not to receive such information.
                </li>
                <li>To manage your requests.</li>
                <li>For other purposes: we may use your information for other purposes, such as data analysis, identifying usage 
                    trends, determining the effectiveness of our promotional campaigns and to evaluate and improve our Service, 
                    products, services, marketing and your experience.
                </li>
                </p>
                <h3 style={{ textAlign: 'left' }}>Use of Google User Data</h3>
                <p>The Google user data we collect is strictly used for: </p>
                <li>Communicating with you regarding updates, service information, and support</li>
                <li>Personalizing your experience while using our Service</li>
                <p>
                We do not share your Google user data with third parties except as necessary to provide the services as described
                in this Privacy Policy, or when legally required.
                </p>
            </div>
            <div className="row-privacy-policy justify-content-center">
                <h4>3. Retention of your Personal Data</h4>
                <p>
                    The Company will retain your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. 
                    We will retain and use your Personal Data to the extent necessary to comply with our legal obligations (for example, 
                    if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements 
                    and policies.
                </p>
                <p>
                    The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter 
                    period of time, except when this data is used to strengthen the security or to improve the functionality of 
                    our Service, or we are legally obligated to retain this data for longer time periods.
                </p>
            </div>
            <div className="row-privacy-policy justify-content-center">
                <h4>4. Transfer of your personal data</h4>
                <p>
                    Your information, including Personal Data, is processed at the Company's operating offices and in any other 
                    places where the parties involved in the processing are located. It means that this information may be transferred 
                    to — and maintained on — computers located outside of your state, province, country or other governmental 
                    jurisdiction where the data protection laws may differ from those from your jurisdiction.
                </p>
                <p>
                    Your consent to this Privacy Policy followed by your submission of such information represents your 
                    agreement to that transfer.
                </p>
                <p>
                    The Company will take all steps reasonably necessary to ensure that your data is treated securely and in accordance 
                    with this Privacy Policy and no transfer of your Personal Data will take place to an organization or a country unless 
                    there are adequate controls in place including the security of your data and other personal information.
                </p>
            </div>
            <div className="row-privacy-policy justify-content-center">
                <h4>5. Disclosure of Your Personal Data</h4>
                <p>
                    We may disclose personal information to:
                <li>courts, tribunals, regulatory authorities and law enforcement officers, as required by law, in connection with any 
                    actual or prospective legal proceedings, or in order to establish, exercise or defend our legal rights;
                </li>
                <li>our employees, contractors and/or related entities;</li>
                <li>credit reporting agencies, courts, tribunals and regulatory authorities, in the event you fail to pay for goods or 
                    services we have provided to you;
                    
                </li>
                <li>third parties, including agents or sub-contractors, who assist us in providing information, products, services 
                    or direct marketing to you; 
                </li>
                <li>third parties to collect and process data;</li>
                <li>third party service providers for the purpose of enabling them to provide their services, including (without 
                    limitation) IT service providers, data storage, hosting and server providers, ad networks, analytics, error loggers, 
                    debt collectors, maintenance or problem-solving providers, marketing or advertising providers, professional 
                    advisors and payment systems operators.
                </li>
                </p>
            </div>
            <div className="row-privacy-policy justify-content-center">
                <h4>6. Storage and Security of your information</h4>
                <h3 style={{ textAlign: 'left' }}>Storage of Information</h3>
                <p>
                Your personal data and service data are stored on secure cloud servers. We ensure that our infrastructure meets 
                high standards of data security and reliability. This includes physical, electronic, and procedural safeguards to 
                protect information from loss, theft, misuse, and unauthorized access.
                </p>
                <h3 style={{ textAlign: 'left' }}>Security Measures</h3>
                <p>
                    We employ a variety of security measures to protect the integrity and confidentiality of your data:
                <li>
                    <b>Data Encryption:</b> Your data is encrypted during transit and while at rest to prevent unauthorized access.
                </li>
                <li>
                    <b>Access Controls: </b>We implement strict access controls and authentication measures to limit access to data to 
                    authorized personnel only.
                </li>
                <li>
                    <b>Regular Audits: </b>Our security practices are regularly reviewed and updated to adapt to new threats and vulnerabilities.
                </li>
                <li>
                    <b>Incident Response: </b>We have a comprehensive incident response plan to address potential security breaches and mitigate
                    their impact.
                </li>
                <li>
                    <b>Security of Google User Data: </b>We employ appropriate security measures to maintain the security and integrity of Google
                    user data collected through our Service. These measures include robust data encryption and stringent access controls that
                    ensure only authorized personnel have access to your information.
                </li>
                </p>
            </div>
            <div className="row-privacy-policy justify-content-center">
                <h4>7. User rights</h4>
                <p>
                    You have the right to access, update, or delete your personal information directly through your account settings on 
                    BackupMeraki. For additional assistance, please contact us at contact@backupmeraki.com.
                </p>
            </div>
            <div className="row-privacy-policy justify-content-center">
                <h4>8. Legal basis for processing</h4>
                <p>
                    Your consent to this Privacy Policy coupled with your voluntary submission of personal data, represents your agreement 
                    to this processing. We process your data to fulfill our contractual obligations and enhance service performance.
                </p>
            </div>
            <div className="row-privacy-policy justify-content-center">
                <h4>9. Changes to this Privacy Policy</h4>
                <p>
                    We may update this Privacy Policy periodically. We will notify you of any significant changes by posting the new policy 
                    on this page and updating the "Effective Date". You are encouraged to review this policy periodically.
                </p>
            </div>
            <div className="row-privacy-policy justify-content-center">
                <h4>10. Contact Us</h4>
                <p>
                    For any questions about this Privacy Policy or our privacy practices, please contact us:
                    <li>Email: contact@backupmeraki.com</li>
                    <li>Address: Level 2, 11-31 York St, Sydney, NSW 2000, Australia</li>
                </p>
            </div>
        </Container>
    );
}

export default PrivacyPolicy;