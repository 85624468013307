import { Container } from "react-bootstrap";
import './TermsOfService.css';

function TermsOfService() {
    return (
        <Container data-aos="fade-up" className="main-content">
            <div className="section-title">
                <h2>Terms of Service</h2>
            </div>
            <div className="row-privacy-policy justify-content-center">
                <p>
                    <b>Effective Date: April 20, 2024</b>
                </p>
                <h4>Introduction.</h4>
                <p>
                    Welcome to BackupMeraki, a service provided by Nexivity Pty Ltd ("Company", "the Company"). 
                    These Terms of Service ("Terms") govern your access to and use of the BackupMeraki services ("Service") 
                    available via our websites located at {}
                    <a href="https://backupmeraki.com">backupmeraki.com</a> and {}
                    <a href="https://app.backupmeraki.com">app.backupmeraki.com</a>.
                    By accessing or using our Service, you agree to be bound by these Terms and all terms incorporated by reference. 
                    If you do not agree to all of these terms, do not use our Service.
                </p>
            </div>
            <div className="row-privacy-policy justify-content-center">
                <h4>1. Services Description</h4>
                <p>
                    BackupMeraki, provided by the Company, offers an online tool that enables users to back up their Cisco Meraki 
                    Dashboard configuration using an API. The Service is available under various subscription plans, including 
                    free and paid options. To deliver these services, the Company requires the use of your Cisco Meraki API key, 
                    which is stored securely. Additionally, the Company securely stores the backup data generated during the backup 
                    process on its servers. You may delete your API key and backup data from our Service at your discretion.
                </p>
            </div>
            <div className="row-privacy-policy justify-content-center">
                <h4>2. Eligibility</h4>
                <p>
                    You must be at least 18 years old and capable of forming a binding contract with the Company to use the Service. 
                    By using the Service, you represent and warrant that you meet these eligibility requirements.
                </p>
            </div>
            <div className="row-privacy-policy justify-content-center">
                <h4>3. Account Registration and Use</h4>
                <p>
                    You must register for an account to access certain features of the Service. You are responsible for maintaining 
                    the confidentiality of your account information and for all activities that occur under your account. 
                    The Company reserves the right to suspend or terminate your account if you are found to be in breach of these Terms.
                </p>
                <p>
                <b>Account Termination: </b>
                    The Company reserves the right to terminate or suspend your account at any time for any reason, including but 
                    not limited to, breach of these Terms, abuse of the Service, or unauthorized use.
                </p>
            </div>
            <div className="row-privacy-policy justify-content-center">
                <h4>4. Intellectual Property Rights</h4>
                <p>
                    All intellectual property rights in and to the Service, including but not limited to design, structure, and content, 
                    are owned by or licensed to the Company. You are granted a non-exclusive, non-transferable, revocable license to 
                    access and use the Service strictly in accordance with these Terms.
                </p>
            </div>
            <div className="row-privacy-policy justify-content-center">
                <h4>5. User Conduct</h4>
                <p>
                    You agree not to use the Service in any manner that could disable, overburden, damage, or impair the Service or 
                    interfere with any other party's use of the Service, including their ability to engage in real time activities 
                    through the Service.
                </p>
            </div>
            <div className="row-privacy-policy justify-content-center">
                <h4>6. Service Availability</h4>
                <p>
                    The Company aims to provide a reliable and available Service but makes no guarantees of uninterrupted or error-free operation. 
                    The Service may be subject to delays, cancellations, and other disruptions. 
                    The Company makes no representation or warranty regarding the reliability, availability, or timeliness of the Service.
                </p>
            </div>
            <div className="row-privacy-policy justify-content-center">
                <h4>7. Limitation of Liability</h4>
                <p>
                    In no event will the Company, its affiliates, agents, directors, employees, suppliers, or licensors be liable for 
                    any indirect, punitive, incidental, special, consequential, or exemplary damages, including without limitation, 
                    damages for loss of profits, goodwill, use, data or other intangible losses, arising out of or relating to the use of, 
                    or inability to use, the Service.
                </p>
            </div>
            <div className="row-privacy-policy justify-content-center">
                <h4>8. Dispute Resolution</h4>
                <p>
                    All disputes arising under these Terms will be resolved through binding arbitration in accordance with the rules 
                    of a recognized arbitration association as agreed upon by the parties.
                </p>
            </div>
            <div className="row-privacy-policy justify-content-center">
                <h4>9. Modifications to Terms</h4>
                <p>
                    The Company reserves the right to modify these Terms at any time. All modifications will be posted on our website 
                    and effective immediately upon posting. Your continued use of the Service after any modification confirms your 
                    acceptance of the new Terms.
                </p>
            </div>
            <div className="row-privacy-policy justify-content-center">
                <h4>10. General Provisions</h4>
                <p>
                    <b>No Waiver: </b>No failure or delay by the Company in exercising any right or remedy provided under these 
                    Terms or by law shall constitute a waiver of that or any other right or remedy.
                </p>
                <p>
                    <b>Severability: </b>If any provision or part-provision of these Terms is or becomes invalid, illegal, 
                    or unenforceable, it shall be deemed modified to the minimum extent necessary to make it valid, legal, and enforceable. 
                    If such modification is not possible, the relevant provision or part-provision shall be deemed deleted.
                </p>
            </div>
            <div className="row-privacy-policy justify-content-center">
                <h4>11. Contact Information</h4>
                <p>
                    For any questions or inquiries regarding these Terms, please contact us at:
                    <li>Email: contact@backupmeraki.com</li>
                    <li>Address: Level 2, 11-31 York St, Sydney, NSW 2000, Australia</li>
                </p>
            </div>
        </Container>
    );
}

export default TermsOfService;