import { Authenticator, View } from "@aws-amplify/ui-react";
import { Amplify } from "aws-amplify";
import Aos from "aos";
import React, { useEffect } from "react";
import { Route, Routes, BrowserRouter } from "react-router-dom";

import ScrollToTop from "../../utils/ScrollToTop";
import Header from "../Header";
import Footer from "../Footer";
import Home from "../../pages/Home/Home";
import Security from "../../pages/Security/Security";
import Contact from "../../pages/Contact/Contact";
import Restore from "../../pages/Restore/Restore";
import Howitworks from "../../pages/Howitworks/Howitworks";
import Pricing from "../../pages/Pricing/Pricing";
import Aboutus from "../../pages/Aboutus/Aboutus";
import PrivacyPolicy from "../../pages/PrivacyPolicy/PrivacyPolicy";
import TermsOfService from "../../pages/TermsOfService/TermsOfService";
import awsConfig from "../../aws-exports";
import "./styles.scss";
import { AuthProvider } from "../../contexts/auth";

const isLocalhost = Boolean(
  window.location.hostname === "localhost" ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === "[::1]" ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);
const isDev = Boolean(window.location.hostname.split(".")[0] === "dev");

const [localRedirectSignIn, devRedirectSignIn, productionRedirectSignIn] =
  awsConfig.oauth.redirectSignIn.split(",");

const [localRedirectSignOut, devRedirectSignOut, productionRedirectSignOut] =
  awsConfig.oauth.redirectSignOut.split(",");
const updatedAwsConfig = {
  ...awsConfig,
  oauth: {
    ...awsConfig.oauth,
    redirectSignIn: isLocalhost
      ? localRedirectSignIn
      : isDev
      ? devRedirectSignIn
      : productionRedirectSignIn,
    redirectSignOut: isLocalhost
      ? localRedirectSignOut
      : isDev
      ? devRedirectSignOut
      : productionRedirectSignOut,
  },
};
Amplify.configure(updatedAwsConfig);

export default function Root() {
  useEffect(() => {
    Aos.init({
      duration: 1000,
      once: true,
    });
  }, []);

  return (
    <AuthProvider>
      <Authenticator.Provider>
        <View>
          <BrowserRouter>
            <Header />
            <section id="hero" className="padd-section text-center">
              <ScrollToTop />
              <Routes>
                <Route exact path="/" element={<Home />} />
                <Route path="restore" element={<Restore />} />
                <Route path="security" element={<Security />} />
                <Route path="contact" element={<Contact />} />
                <Route path="how-it-works" element={<Howitworks />} />
                <Route path="pricing" element={<Pricing />} />
                <Route path="about-us" element={<Aboutus />} />
                <Route path="privacy-policy" element={<PrivacyPolicy />} />
                <Route path="terms-of-service" element={<TermsOfService />} />
              </Routes>
            </section>
            <Footer />
          </BrowserRouter>
        </View>
      </Authenticator.Provider>
    </AuthProvider>
  );
}
