import { Container } from "react-bootstrap";
import './Pricing.css';
import { useState } from "react";
import { useNavigate } from 'react-router-dom';


function Pricing() {
    const navigate  = useNavigate();
    const [selectedPricingPanel, setSelectedPricingPanel] = useState('monthly')
    const btnOnClickMonthly = () => {
        setSelectedPricingPanel('monthly')
    }
    const btnOnClickYearly = () => {
        setSelectedPricingPanel('yearly')
    }
    
    const btnOnClickSubscribeNow = () => {
        const isDev = Boolean(window.location.hostname.split(".")[0] === "dev");
        const isLocalhost = Boolean(
            window.location.hostname === "localhost" ||
              // [::1] is the IPv6 localhost address.
              window.location.hostname === "[::1]" ||
              // 127.0.0.1/8 is considered localhost for IPv4.
              window.location.hostname.match(
                /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
              )
        );
        let externalLink = 'https://app.backupmeraki.com/settings/plans';
        // const account_id = objUserData["custom:account_id"];
        // const prefilled_email = objUserData["email"];
        if(isDev || isLocalhost){
          externalLink = `https://devapp.backupmeraki.com/settings/plans`;
        }else{
          externalLink = `https://app.backupmeraki.com/settings/plans`
        }
        // // Open the external link in a new tab
        window.open(externalLink, '_blank');
    }
    const btnOnContactUs = () => {
        navigate('/about-us');
    }
    return (
        <Container data-aos="fade-up" className="main-content">
            <div className="section-title text-center">
                <h2>Pricing</h2>
                {/* <h3>API-based service.</h3> */}
            </div>
            <div className="row-howitworks justify-content-center">
                <div className="PricingTable is-roundedBorder is-blackButtonText is-lightColorBackground has-threeItems flex-container direction-column justify-content-center align-items-center"
                    data-testid="pricing-table-container"
                    style={{ "--pt-color-primary": "#71c55d", backgroundColor: "rgb(255, 255, 255)" }}
                    // style={{ "--pt-color-primary": "#71c55d" }}
                    // style="--pt-color-primary: #71c55d; background-color: rgb(255, 255, 255);"
                >
                    <div className="IntervalTabs-refContainer">
                        <div className="Tabs IntervalTabs is-insettabs is-desktop">
                            <div className="Tabs-Container">
                                <div role="tablist" aria-orientation="horizontal" aria-label="Billing intervals"
                                    className="Tabs-TabList">
                                    <div role="presentation"
                                        // className="Tabs-TabListItemContainer Tabs-TabListItemContainer--is-selected"
                                        className={`Tabs-TabListItemContainer ${selectedPricingPanel === 'monthly' ? ' Tabs-TabListItemContainer--is-selected' : ''} `} 
                                    >
                                        <button
                                            className={`Tabs-TabListItem ${selectedPricingPanel === 'monthly' ? ' Tabs-TabListItem--is-selected is-button-selected' : ''} `} 
                                            // className="Tabs-TabListItem Tabs-TabListItem--is-selected" 
                                            id="1-month-tab"
                                            data-testid="1-month-tab-button" role="tab" type="button"
                                            aria-controls="1-month-tab-panel" aria-label="Monthly" aria-selected="true" tabIndex="0"
                                            title="Monthly"
                                            onClick={() => { btnOnClickMonthly() }}
                                        >
                                            <div className="Tabs-TabListItemContent">
                                                <div className="Tabs-TabListPaymentMethod">
                                                    <div className="Tabs-TabListPaymentLabel" data-text="Monthly">Monthly</div>
                                                </div>
                                            </div>
                                        </button>
                                    </div>
                                    <div role="presentation" 
                                        // className="Tabs-TabListItemContainer"
                                        className={`Tabs-TabListItemContainer ${selectedPricingPanel === 'yearly' ? ' Tabs-TabListItemContainer--is-selected' : ''} `} 
                                    >
                                        <button 
                                            // className="Tabs-TabListItem"
                                            className={`Tabs-TabListItem  ${selectedPricingPanel === 'yearly' ? ' Tabs-TabListItem--is-selected is-button-selected' : ''} `} 
                                            id="1-year-tab" data-testid="1-year-tab-button" role="tab" type="button"
                                            aria-controls="1-year-tab-panel" aria-label="Yearly" aria-selected="false" tabIndex="-1"
                                            title="Yearly"
                                            onClick={() => { btnOnClickYearly() }}
                                        >
                                        <div className="Tabs-TabListItemContent">
                                            <div className="Tabs-TabListPaymentMethod">
                                                <div className="Tabs-TabListPaymentLabel" data-text="Yearly">Yearly</div>
                                            </div>
                                        </div>
                                    </button></div>
                                    <span className="IntervalTabs-glider"
                                        // style="background-color: rgb(113, 197, 93); --offset: 2px; height: 36px; width: 117px;"
                                        style={{
                                            backgroundColor: "rgb(113, 197, 93)",
                                            "--offset": "2px",
                                            height: "36px",
                                            width: "117px",
                                        }}
                                    ></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="PricingTable-grid">
                        <div className="PriceColumn flex-container direction-column justify-content-flex-start align-items-center"
                            data-testid="price-column" 
                            // style="--pt-animate-in-order: 0;"
                            style={{ "--pt-animate-in-order": 0 }}
                        >
                            <div
                                className="PriceColumn-content flex-container direction-column justify-content-flex-start align-items-center">
                                <div className="PriceColumn-aboutProduct flex-container direction-column">
                                    <div className="PriceColumn-badge is-placeholderForHeight flex-container"></div>
                                    <div className="PriceColumn-textAndProductImage flex-container">
                                        <div className="PriceColumn-textContainerHeightAligner" style={{ height: "84px" }}>
                                            <div className="PriceColumn-textContainer flex-container direction-column">
                                                <div className="PriceColumn-name flex-container"><span
                                                    className="Text Text-color--default Text-fontSize--20 Text-fontWeight--600">Free</span>
                                                </div><span
                                                    className="PriceColumn-description Text Text-color--default Text-fontSize--14">Back
                                                    up you Meraki Organization anytime for free.</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="PriceColumn-priceAndButton flex-container direction-column">
                                    <div className="PriceColumn-priceContainerHeightAligner" style={{ height: "44px" }}>
                                        <div className="PriceColumn-priceContainer">
                                            <div className="flex-container spacing-4 direction-column align-items-flex-start">
                                                <div className="flex-container align-items-center"><span
                                                    className="PriceColumn-price Text Text-color--default Text-fontSize--36 Text-fontWeight--700"><span
                                                        className="CurrencyAmount">$0</span></span><span
                                                            className="PriceColumn-interval Text Text-color--default Text-fontSize--13">per
                                                        <br/>{ selectedPricingPanel === 'monthly' ? 'month' : 'year'}</span></div>
                                            </div>
                                        </div>
                                    </div>
                                        <button className="Button PriceColumn-button Button--primary Button--lg" type="button"
                                            // style="background-color: rgb(113, 197, 93); border-color: rgb(113, 197, 93);"
                                            style={{ backgroundColor: "rgb(113, 197, 93)", borderColor: "rgb(113, 197, 93)" }}
                                            // style="background-color: rgb(113, 197, 93); border-color: rgb(113, 197, 93);"
                                            onClick={() => { btnOnClickSubscribeNow() }}
                                        >
                                        <div className="flex-container justify-content-center align-items-center"><span
                                            className="Text Text-color--default Text-fontWeight--500 Text--truncate">Start Free Trial</span>
                                        </div>
                                    </button>
                                </div>
                            </div>
                            <div className="PriceColumn-featureListContainer flex-container direction-column"><span
                                className="PriceColumn-featureListHeader Text Text-color--default Text-fontSize--14">This
                                includes:</span>
                                <div className="PriceColumn-featureList flex-container direction-column align-items-flex-start">
                                    <div className="PriceColumn-feature flex-container align-items-flex-start">
                                        <div className="PriceColumn-checkContainer flex-container"><svg
                                            className="InlineSVG Icon PriceColumn-check Icon--sm" focusable="false" fill="#1a1a1a"
                                            color="#1a1a1a" fillOpacity="0.5" height="16" viewBox="0 0 16 16" width="16">
                                            <path
                                                d="m8 16c-4.418278 0-8-3.581722-8-8s3.581722-8 8-8 8 3.581722 8 8-3.581722 8-8 8zm3.0832728-11.00479172-4.0832728 4.09057816-1.79289322-1.79289322c-.39052429-.39052429-1.02368927-.39052429-1.41421356 0s-.39052429 1.02368927 0 1.41421356l2.5 2.50000002c.39052429.3905243 1.02368927.3905243 1.41421356 0l4.79037962-4.79768495c.3905243-.3905243.3905243-1.02368927 0-1.41421357-.3905243-.39052429-1.0236893-.39052429-1.4142136 0z"
                                                fillRule="evenodd"></path>
                                        </svg></div><span
                                            className="PriceColumn-featureText Text Text-color--default Text-fontSize--14">Number of
                                            organizations: 1</span>
                                    </div>
                                    <div className="PriceColumn-feature flex-container align-items-flex-start">
                                        <div className="PriceColumn-checkContainer flex-container"><svg
                                            className="InlineSVG Icon PriceColumn-check Icon--sm" focusable="false" fill="#1a1a1a"
                                            color="#1a1a1a" fillOpacity="0.5" height="16" viewBox="0 0 16 16" width="16">
                                            <path
                                                d="m8 16c-4.418278 0-8-3.581722-8-8s3.581722-8 8-8 8 3.581722 8 8-3.581722 8-8 8zm3.0832728-11.00479172-4.0832728 4.09057816-1.79289322-1.79289322c-.39052429-.39052429-1.02368927-.39052429-1.41421356 0s-.39052429 1.02368927 0 1.41421356l2.5 2.50000002c.39052429.3905243 1.02368927.3905243 1.41421356 0l4.79037962-4.79768495c.3905243-.3905243.3905243-1.02368927 0-1.41421357-.3905243-.39052429-1.0236893-.39052429-1.4142136 0z"
                                                fillRule="evenodd"></path>
                                        </svg></div><span
                                            className="PriceColumn-featureText Text Text-color--default Text-fontSize--14">On-Demand
                                            Backup</span>
                                    </div>
                                    <div className="PriceColumn-feature flex-container align-items-flex-start">
                                        <div className="PriceColumn-checkContainer flex-container"><svg
                                            className="InlineSVG Icon PriceColumn-check Icon--sm" focusable="false" fill="#1a1a1a"
                                            color="#1a1a1a" fillOpacity="0.5" height="16" viewBox="0 0 16 16" width="16">
                                            <path
                                                d="m8 16c-4.418278 0-8-3.581722-8-8s3.581722-8 8-8 8 3.581722 8 8-3.581722 8-8 8zm3.0832728-11.00479172-4.0832728 4.09057816-1.79289322-1.79289322c-.39052429-.39052429-1.02368927-.39052429-1.41421356 0s-.39052429 1.02368927 0 1.41421356l2.5 2.50000002c.39052429.3905243 1.02368927.3905243 1.41421356 0l4.79037962-4.79768495c.3905243-.3905243.3905243-1.02368927 0-1.41421357-.3905243-.39052429-1.0236893-.39052429-1.4142136 0z"
                                                fillRule="evenodd"></path>
                                        </svg></div><span
                                            className="PriceColumn-featureText Text Text-color--default Text-fontSize--14">Backup to
                                            Email</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="PriceColumn is-highlight flex-container direction-column justify-content-flex-start align-items-center"
                            data-testid="price-column" 
                            // style="--pt-animate-in-order: 1;"
                            style={{ "--pt-animate-in-order": 1 }}
                        >
                            <div
                                className="PriceColumn-content flex-container direction-column justify-content-flex-start align-items-center">
                                <div className="PriceColumn-aboutProduct flex-container direction-column">
                                    <div className="PriceColumn-badge flex-container">
                                        <div className="Badge is-highlightBadge flex-container align-items-center"><span
                                            className="Badge-text Text Text-color--default Text-fontSize--12 Text-fontWeight--500">Most
                                            popular</span></div>
                                    </div>
                                    <div className="PriceColumn-textAndProductImage flex-container">
                                        <div className="PriceColumn-textContainerHeightAligner" style={{ height: "84px" }}>
                                            <div className="PriceColumn-textContainer flex-container direction-column">
                                                <div className="PriceColumn-name flex-container"><span
                                                    className="Text Text-color--default Text-fontSize--20 Text-fontWeight--600">Premium</span>
                                                </div><span
                                                    className="PriceColumn-description Text Text-color--default Text-fontSize--14">Automatic
                                                    Backups and Backup History storage for your peace of mind.</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="PriceColumn-priceAndButton flex-container direction-column">
                                    <div className="PriceColumn-priceContainerHeightAligner" style={{ height: "44px" }}>
                                        <div className="PriceColumn-priceContainer">
                                            <div className="flex-container spacing-4 direction-column align-items-flex-start">
                                                <div className="flex-container align-items-center"><span
                                                    className="PriceColumn-price Text Text-color--default Text-fontSize--36 Text-fontWeight--700"><span
                                                        className="CurrencyAmount">${ selectedPricingPanel === 'monthly' ? '29' : '290'}</span></span><span
                                                            className="PriceColumn-interval Text Text-color--default Text-fontSize--13">per
                                                        organization <br />per { selectedPricingPanel === 'monthly' ? 'month' : 'year'}</span></div>
                                            </div>
                                        </div>
                                    </div>
                                        <button className="Button PriceColumn-button Button--primary Button--lg" type="button"
                                            style={{ backgroundColor: "rgb(113, 197, 93)", borderColor: "rgb(113, 197, 93)" }}
                                            // style="background-color: rgb(113, 197, 93); border-color: rgb(113, 197, 93);"
                                            onClick={() => { btnOnClickSubscribeNow() }}
                                        >
                                        <div className="flex-container justify-content-center align-items-center"><span
                                            className="Text Text-color--default Text-fontWeight--500 Text--truncate">Start Free Trial</span></div>
                                    </button>
                                </div>
                            </div>
                            <div className="PriceColumn-featureListContainer flex-container direction-column"><span
                                className="PriceColumn-featureListHeader Text Text-color--default Text-fontSize--14">This
                                includes:</span>
                                <div className="PriceColumn-featureList flex-container direction-column align-items-flex-start">
                                    <div className="PriceColumn-feature flex-container align-items-flex-start">
                                        <div className="PriceColumn-checkContainer flex-container"><svg
                                            className="InlineSVG Icon PriceColumn-check Icon--sm" focusable="false" fill="#1a1a1a"
                                            color="#1a1a1a" fillOpacity="0.5" height="16" viewBox="0 0 16 16" width="16">
                                            <path
                                                d="m8 16c-4.418278 0-8-3.581722-8-8s3.581722-8 8-8 8 3.581722 8 8-3.581722 8-8 8zm3.0832728-11.00479172-4.0832728 4.09057816-1.79289322-1.79289322c-.39052429-.39052429-1.02368927-.39052429-1.41421356 0s-.39052429 1.02368927 0 1.41421356l2.5 2.50000002c.39052429.3905243 1.02368927.3905243 1.41421356 0l4.79037962-4.79768495c.3905243-.3905243.3905243-1.02368927 0-1.41421357-.3905243-.39052429-1.0236893-.39052429-1.4142136 0z"
                                                fillRule="evenodd"></path>
                                        </svg></div><span
                                            className="PriceColumn-featureText Text Text-color--default Text-fontSize--14">Number of
                                            organizations: ∞</span>
                                    </div>
                                    <div className="PriceColumn-feature flex-container align-items-flex-start">
                                        <div className="PriceColumn-checkContainer flex-container"><svg
                                            className="InlineSVG Icon PriceColumn-check Icon--sm" focusable="false" fill="#1a1a1a"
                                            color="#1a1a1a" fillOpacity="0.5" height="16" viewBox="0 0 16 16" width="16">
                                            <path
                                                d="m8 16c-4.418278 0-8-3.581722-8-8s3.581722-8 8-8 8 3.581722 8 8-3.581722 8-8 8zm3.0832728-11.00479172-4.0832728 4.09057816-1.79289322-1.79289322c-.39052429-.39052429-1.02368927-.39052429-1.41421356 0s-.39052429 1.02368927 0 1.41421356l2.5 2.50000002c.39052429.3905243 1.02368927.3905243 1.41421356 0l4.79037962-4.79768495c.3905243-.3905243.3905243-1.02368927 0-1.41421357-.3905243-.39052429-1.0236893-.39052429-1.4142136 0z"
                                                fillRule="evenodd"></path>
                                        </svg></div><span
                                            className="PriceColumn-featureText Text Text-color--default Text-fontSize--14">On-Demand
                                            Backup</span>
                                    </div>
                                    <div className="PriceColumn-feature flex-container align-items-flex-start">
                                        <div className="PriceColumn-checkContainer flex-container"><svg
                                            className="InlineSVG Icon PriceColumn-check Icon--sm" focusable="false" fill="#1a1a1a"
                                            color="#1a1a1a" fillOpacity="0.5" height="16" viewBox="0 0 16 16" width="16">
                                            <path
                                                d="m8 16c-4.418278 0-8-3.581722-8-8s3.581722-8 8-8 8 3.581722 8 8-3.581722 8-8 8zm3.0832728-11.00479172-4.0832728 4.09057816-1.79289322-1.79289322c-.39052429-.39052429-1.02368927-.39052429-1.41421356 0s-.39052429 1.02368927 0 1.41421356l2.5 2.50000002c.39052429.3905243 1.02368927.3905243 1.41421356 0l4.79037962-4.79768495c.3905243-.3905243.3905243-1.02368927 0-1.41421357-.3905243-.39052429-1.0236893-.39052429-1.4142136 0z"
                                                fillRule="evenodd"></path>
                                        </svg></div><span
                                            className="PriceColumn-featureText Text Text-color--default Text-fontSize--14">Scheduled
                                            (Automatic) Backup</span>
                                    </div>
                                    <div className="PriceColumn-feature flex-container align-items-flex-start">
                                        <div className="PriceColumn-checkContainer flex-container"><svg
                                            className="InlineSVG Icon PriceColumn-check Icon--sm" focusable="false" fill="#1a1a1a"
                                            color="#1a1a1a" fillOpacity="0.5" height="16" viewBox="0 0 16 16" width="16">
                                            <path
                                                d="m8 16c-4.418278 0-8-3.581722-8-8s3.581722-8 8-8 8 3.581722 8 8-3.581722 8-8 8zm3.0832728-11.00479172-4.0832728 4.09057816-1.79289322-1.79289322c-.39052429-.39052429-1.02368927-.39052429-1.41421356 0s-.39052429 1.02368927 0 1.41421356l2.5 2.50000002c.39052429.3905243 1.02368927.3905243 1.41421356 0l4.79037962-4.79768495c.3905243-.3905243.3905243-1.02368927 0-1.41421357-.3905243-.39052429-1.0236893-.39052429-1.4142136 0z"
                                                fillRule="evenodd"></path>
                                        </svg></div><span
                                            className="PriceColumn-featureText Text Text-color--default Text-fontSize--14">Secure Cloud
                                            Storage</span>
                                    </div>
                                    <div className="PriceColumn-feature flex-container align-items-flex-start">
                                        <div className="PriceColumn-checkContainer flex-container"><svg
                                            className="InlineSVG Icon PriceColumn-check Icon--sm" focusable="false" fill="#1a1a1a"
                                            color="#1a1a1a" fillOpacity="0.5" height="16" viewBox="0 0 16 16" width="16">
                                            <path
                                                d="m8 16c-4.418278 0-8-3.581722-8-8s3.581722-8 8-8 8 3.581722 8 8-3.581722 8-8 8zm3.0832728-11.00479172-4.0832728 4.09057816-1.79289322-1.79289322c-.39052429-.39052429-1.02368927-.39052429-1.41421356 0s-.39052429 1.02368927 0 1.41421356l2.5 2.50000002c.39052429.3905243 1.02368927.3905243 1.41421356 0l4.79037962-4.79768495c.3905243-.3905243.3905243-1.02368927 0-1.41421357-.3905243-.39052429-1.0236893-.39052429-1.4142136 0z"
                                                fillRule="evenodd"></path>
                                        </svg></div><span
                                            className="PriceColumn-featureText Text Text-color--default Text-fontSize--14">Secure API
                                            Storage</span>
                                    </div>
                                    <div className="PriceColumn-feature flex-container align-items-flex-start">
                                        <div className="PriceColumn-checkContainer flex-container"><svg
                                            className="InlineSVG Icon PriceColumn-check Icon--sm" focusable="false" fill="#1a1a1a"
                                            color="#1a1a1a" fillOpacity="0.5" height="16" viewBox="0 0 16 16" width="16">
                                            <path
                                                d="m8 16c-4.418278 0-8-3.581722-8-8s3.581722-8 8-8 8 3.581722 8 8-3.581722 8-8 8zm3.0832728-11.00479172-4.0832728 4.09057816-1.79289322-1.79289322c-.39052429-.39052429-1.02368927-.39052429-1.41421356 0s-.39052429 1.02368927 0 1.41421356l2.5 2.50000002c.39052429.3905243 1.02368927.3905243 1.41421356 0l4.79037962-4.79768495c.3905243-.3905243.3905243-1.02368927 0-1.41421357-.3905243-.39052429-1.0236893-.39052429-1.4142136 0z"
                                                fillRule="evenodd"></path>
                                        </svg></div><span
                                            className="PriceColumn-featureText Text Text-color--default Text-fontSize--14">Backup
                                            History</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="PriceColumn flex-container direction-column justify-content-flex-start align-items-center"
                            data-testid="price-column" 
                            // style="--pt-animate-in-order: 2;"
                            style={{ "--pt-animate-in-order": 2 }}
                        >
                            <div
                                className="PriceColumn-content flex-container direction-column justify-content-flex-start align-items-center">
                                <div className="PriceColumn-aboutProduct flex-container direction-column">
                                    <div className="PriceColumn-badge is-placeholderForHeight flex-container"></div>
                                    <div className="PriceColumn-textAndProductImage flex-container">
                                        <div className="PriceColumn-textContainerHeightAligner" style={{ height: "84px" }}>
                                            <div className="PriceColumn-textContainer flex-container direction-column">
                                                <div className="PriceColumn-name flex-container"><span
                                                    className="Text Text-color--default Text-fontSize--20 Text-fontWeight--600">Enterprise</span>
                                                </div><span
                                                    className="PriceColumn-description Text Text-color--default Text-fontSize--14">Need
                                                    something custom? Reach out to our sales.</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="PriceColumn-priceAndButton flex-container direction-column">
                                    <div className="PriceColumn-priceContainerHeightAligner" style={{ height: "44px" }}></div>
                                    <button
                                        className="Button PriceColumn-button Button--primary Button--lg" type="button"
                                        // style="background-color: rgb(113, 197, 93); border-color: rgb(113, 197, 93);"
                                        style={{ backgroundColor: "rgb(113, 197, 93)", borderColor: "rgb(113, 197, 93)" }}
                                        onClick={() => { btnOnContactUs() }}
                                    >
                                        <div className="flex-container justify-content-center align-items-center"><span
                                            className="Text Text-color--default Text-fontWeight--500 Text--truncate">Contact
                                            us</span></div>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Container>
    );
}

export default Pricing;