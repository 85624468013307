import { Container } from "react-bootstrap";
import './Howitworks.css';
import imgLoadorg from './load_org.png';
import imgBackup from './backup.png';
import imgFilestructure from './file_structure.png';
import imgSettings from './settings.png';

function Howitworks() {
    return (
        <Container data-aos="fade-up" className="main-content">
            <div className="section-title text-center">
                <h2>How it works</h2>
                <h3>API-based service.</h3>
            </div>
            <div className="row-howitworks justify-content-center">
                <p className="p-howitworks-indent">
                Cisco Meraki API is an extremely powerful platform that allows you to interact with your Dashboard
                programmatically. As a result, it enables developers to build functionality beyond standard features.
                Our service is leveraging API to export your Dashboard configuration.
                </p>

                <h3>What is happening under the hood?</h3>
                <p className="p-howitworks-indent">
                During the backup process, we send a number of GET (read-only) API calls on your behalf and save the data
                into the files. All configuration-related API calls can be divided into three categories:
                <li className="p-howitworks-indent">Organization settings,</li>
                <li className="p-howitworks-indent">Network configuration,</li>
                <li className="p-howitworks-indent">Device-specific configuration.</li>
                So the files and folders in your backup archive will follow this structure.
                </p>
                <img src={imgFilestructure}alt="img"/>
                <p>
                API calls are usually designed for a specific aspect of configuration such as VLANs configuration,
                port settings, DHCP settings, etc. Each file in the archive corresponds to a particular piece of config.
                Let's take a look at the following example:
                </p>
                <img src={imgSettings}alt="img"/>

                <p>
                Lastly, there are a lot of calls to make to collect ALL the
                settings. If you have many devices and many networks, the overall completion time increases considerably.
                When the backup is completed, the link will be sent to your email address.
                This link is a pre-signed URL that enables us to securely share backup data with
                you for a very limited period of time (60 minutes only). Make sure that you download your files before
                expiry. After that, we remove the backup files from our server permanently. Also, it is
                important to provide your individual working email instead of a shared company's alias such as
                info@company.com to restrict access to authorized personnel only.
                </p>
            </div>
        </Container>
    );
}

export default Howitworks;