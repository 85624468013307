import { Container } from 'react-bootstrap';
import heroBm from './hero-bm.png';
import imgComply from './comply.png';
import imgExperiment from './experiment.png';
import imgProtect from './protect.png';


function Home() {
    return (
        <Container className="hero-container main-content" data-aos="fade-in">
            <h1>Backup Meraki</h1>
            <h3>Elegant and easy online tool to back up your Cisco Meraki configuration.</h3>
            <img src={heroBm} alt="Hero Imgs" data-aos="zoom-out" data-aos-delay="100"></img>
            <a href="https://app.backupmeraki.com/" target="_blank" className="btn-get-started scrollto">Get Started</a>

            <div className="section-title text-center">
                <h2>Better safe than sorry.</h2>
                <h3>Why do I need to back up my Meraki Dashboard configuration? </h3>
            </div>


            <section className="hero-section">
            <div className="row">

                <div className="col-md-6 col-lg-6" data-aos="zoom-in" data-aos-delay="100">
                    <div className="feature-block">
                        <img src={imgProtect}alt="img" className="img" />
                    </div>
                </div>

                <div className="col-md-6 col-lg-6" data-aos="zoom-in" data-aos-delay="100">
                    <div className="feature-block">
                        {/*<h4>Protect</h4>*/}
                        <h3><b>Protect</b> your Meraki Dashboard configuration from accidental or malicious removal.</h3>
                        <p><svg width="20" height="14" viewBox="0 0 18 13"  fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2 6.125L6.91892 11L16 2"></path></svg>  Accidents happen. Give yourself peace of mind by backing up your data.</p>
                        <p><svg width="20" height="14" viewBox="0 0 18 13"  fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2 6.125L6.91892 11L16 2"></path></svg>  Cisco Meraki Dashboard has no rollback option.
                        Once the configuration is removed (intentionally or accidentally), it cannot be restored. </p>
                        <p><svg width="20" height="14" viewBox="0 0 18 13"  fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2 6.125L6.91892 11L16 2"></path></svg>  Cisco Meraki maintains high standards of
                         availability and security of the Dashboard, however, maintaining the correct settings on the
                         Dashboard is the responsibility of the end user.
                        </p>
                    </div>
                </div>

            </div>

            <div className="row">

                <div className="col-md-6 col-lg-6" data-aos="zoom-in" data-aos-delay="200">
                    <div className="feature-block">
                        <h3><b>Experiment</b> with  Meraki Dashboard settings without fear of losing your current stable configuration.</h3>
                        <p></p>
                        <p><svg width="20" height="14" viewBox="0 0 18 13"  fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2 6.125L6.91892 11L16 2"></path></svg>  Back up your configuration before testing your new API automation application. Just in case.</p>
                        <p><svg width="20" height="14" viewBox="0 0 18 13"  fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2 6.125L6.91892 11L16 2"></path></svg>  Test new Meraki features while keeping a backup copy of the original setup.</p>
                        <p><svg width="20" height="14" viewBox="0 0 18 13"  fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2 6.125L6.91892 11L16 2"></path></svg>  If you are unsure about the impact of the settings you are about to apply on your network, back up your current config in case you need to roll back.</p>
                    </div>
                </div>

                <div className="col-md-6 col-lg-6" data-aos="zoom-in" data-aos-delay="200">
                    <div className="feature-block">
                        <img src={imgExperiment} alt="img" className="img" />
                    </div>
                </div>

            </div>

            <div className="row">

                <div className="col-md-6 col-lg-6" data-aos="zoom-in" data-aos-delay="300">
                    <div className="feature-block">
                        <img src={imgComply} alt="img" className="img" />
                    </div>
                </div>

                <div className="col-md-6 col-lg-6" data-aos="zoom-in" data-aos-delay="300">
                    <div className="feature-block">
                        <h3><b>Comply</b> with policies and regulations.</h3>
                        <p></p>
                        <p><svg width="20" height="14" viewBox="0 0 18 13"  fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2 6.125L6.91892 11L16 2"></path></svg>  Export your Meraki Dashboard configuration for internal documentation or compliance purposes.</p>
                        <p><svg width="20" height="14" viewBox="0 0 18 13"  fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2 6.125L6.91892 11L16 2"></path></svg>  Use exported config for a smooth project handover or your as-built records.</p>
                    </div>
                </div>

            </div>
            </section>
        </Container>
    );
}

export default Home;